// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, signInAnonymously, UserCredential } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { lastValueFrom, of } from "rxjs";

import logx from "../helpers/logx";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXwvIo_-_WuhhQ0yGJ4x0Ew8-6JjS2yQ8",
  authDomain: "planningpoker-20230731.firebaseapp.com",
  databaseURL: "https://planningpoker-20230731-default-rtdb.firebaseio.com",
  projectId: "planningpoker-20230731",
  storageBucket: "planningpoker-20230731.appspot.com",
  messagingSenderId: "1683223524",
  appId: "1:1683223524:web:7de84bff14b03b5287cdf1",
  measurementId: "G-056NDSCW1G",
};

// Initialize Firebase
window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.NODE_ENV === "development";
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

// https://firebase.google.com/docs/app-check/web/recaptcha-provider?hl=pt&authuser=0&_gl=1*pel81s*_ga*MTQ5NjgyNjIzNi4xNjkwNTAyNDk4*_ga_CW55HF8NVT*MTY5MTcyMTA1MC4yOS4xLjE2OTE3MjExMDQuMC4wLjA.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Lc-z5gnAAAAAHcZaAp7bVegZ8ieY-2Ss-luleIe"),
  isTokenAutoRefreshEnabled: true,
});

export default class FirebaseService {
  private userCredential!: UserCredential;
  private signingIn = false;

  get userUid() {
    return this.userCredential?.user.uid;
  }

  signInAnonymouysly() {
    if (this.signingIn) return;
    this.signingIn = true;

    logx.firebase("trying to anonymous signing");

    if (this.userCredential) {
      logx.firebase("user credential found in anonymous signing", this.userUid);
      this.signingIn = false;
      return lastValueFrom(of(null));
    }
    return signInAnonymously(auth)
      .then((_userCredential) => {
        this.userCredential = _userCredential;
        logx.firebase("signed in user uid:", _userCredential.user.uid);
        this.signingIn = false;
        return this.userCredential;
      })
      .catch((error) => {
        logx.error("firebase sign in error", error);
        this.signingIn = false;
        return lastValueFrom(of(null));
      });
  }
}
