import "./assets/colors.css";
import "./assets/style.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import router from "./router";
import AdminService from "./services/admin-service";
import AlertService from "./services/alert-service";
import FirebaseService from "./services/firebase-service";
import { DecouplerContext } from "./services/service-locator/DecouplerContext";
import { ServiceLocator } from "./services/service-locator/ServiceLocator";
import SessionHelper from "./services/session-helper";
import SessionService from "./services/session-service";

let locator = new ServiceLocator();
locator.register("AdminService", AdminService);
locator.register("AlertService", AlertService);
locator.register("FirebaseService", FirebaseService);
locator.register("SessionHelper", SessionHelper);
locator.register("SessionService", SessionService);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <DecouplerContext.Provider value={locator}>
      <RouterProvider router={router} />
    </DecouplerContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
