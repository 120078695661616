import { useState } from "react";
import "./style.scss";

interface IProps {
  id?: string;
  autoFocus?: boolean;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
}

export default function InputText({
  id,
  autoFocus,
  name,
  defaultValue,
  placeholder,
  onChange,
}: IProps) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };

  return (
    <input
      autoFocus={autoFocus}
      className="input-text"
      id={id}
      name={name}
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={handleChange}
    />
  );
}
