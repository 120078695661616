import { BehaviorSubject } from "rxjs";

import logx from "../helpers/logx";
import { fibonacci } from "../helpers/points";
import { Session } from "../models/session";
import { CardItemCount, SessionResult } from "../models/session-result";

export default class SessionHelper {
  static sessionResult: BehaviorSubject<SessionResult> =
    new BehaviorSubject<SessionResult>({ tableCards: [] });

  resultGroup(session: Session) {
    return (
      session.players?.reduce((p, player) => {
        if (p.some((x) => x.value === player.card?.value))
          p.map((x) =>
            x.value === player.card?.value ? { ...x, count: x.count++ } : x
          );
        else if (player.card?.value)
          p.push({
            count: 1,
            weight: player.card.weight,
            value: player.card?.value,
          });

        return p;
      }, [] as CardItemCount[]) ?? []
    );
  }

  calcResultAndAverage(session: Session) {
    const stopped = session.startedAt && session.stoppedAt;
    const resGroup = this.resultGroup(session);

    if (stopped && resGroup.length > 0) {
      const avg = this.calcuteAverage(resGroup.filter((x) => x.value !== "?"));

      logx.service("[sessionResult] update result");
      SessionHelper.sessionResult.next({
        average: { label: "Média", value: avg?.toString() ?? "-", weight: -1 },
        tableCards: resGroup.map((card) => ({
          label: card.count.toString(),
          value: card.value,
          weight: card.weight,
        })),
      });

      return;
    }

    if (
      SessionHelper.sessionResult.value.average !== undefined ||
      SessionHelper.sessionResult.value.tableCards !== fibonacci
    ) {
      logx.service("[sessionResult] cleanup result");
      SessionHelper.sessionResult.next({
        average: undefined,
        tableCards: fibonacci,
      });
    }
  }

  calcuteAverage(cards: CardItemCount[]) {
    const numOfCards = cards.reduce((p, c) => (p += c.count), 0.0);
    const cardsByWeight = (c: CardItemCount) =>
      Number(c.weight) * Number(c.count);
    const sumCardsWeight = cards.reduce((p, c) => (p += cardsByWeight(c)), 0.0);
    return Math.round(((sumCardsWeight / numOfCards) * 100) / 100);
  }
}
