import "./style.scss";

import { CardItem } from "../../models/card-item";
import { Player } from "../../models/player";
import PlayingCard from "../playing-card";

interface IProps {
  started?: boolean;
  stopped?: boolean;
  players: Player[];
}

export default function TableCards({ started, stopped, players }: IProps) {
  const place = (value: any) => (!started && stopped) || !value;
  const active = (value: any) => started && !stopped && value !== undefined;
  const back = (value: any) => started && !stopped && value !== undefined;

  return (
    <div className="table-cards">
      {players.map((player, idx) => (
        <PlayingCard
          key={idx}
          card={{ ...player.card, label: player.name } as CardItem}
          place={place(player.card?.value)}
          back={back(player.card?.value)}
          active={active(player.card?.value)}
        />
      ))}
    </div>
  );
}
