import { CardItem } from "../models/card-item";

export const fibonacci: CardItem[] = [
  { value: "0", weight: 0 },
  { value: "1", weight: 1 },
  { value: "2", weight: 2 },
  { value: "3", weight: 3 },
  { value: "5", weight: 5 },
  { value: "8", weight: 8 },
  { value: "13", weight: 13 },
  { value: "21", weight: 21 },
  { value: "34", weight: 34 },
  { value: "?", weight: -1 },
];
