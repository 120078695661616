import { useEffect, useState } from "react";
import "./style.scss";
import { useService } from "../../services/service-locator/DecouplerContext";
import AlertService from "../../services/alert-service";
import Button from "../button";

export default function AlertPopup() {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState<string | undefined>();
  const [visible, setVisible] = useState(false);
  const [acceptButton, setAcceptButton] = useState("");
  const [Children, setChildren] = useState<any>();
  const [acceptButtonClick, setAcceptButtonClick] = useState<() => void>();
  const service = useService<AlertService>("AlertService");

  const handleAcceptClick = () => {
    acceptButtonClick && acceptButtonClick();
  };

  useEffect(() => {
    service.alertContent.subscribe((value) => {
      setChildren(value.children);
      setVisible(value.visible ?? false);
      setTitle(value.title);
      setMessage(value.message);
      setAcceptButton(value.acceptButton);
      setAcceptButtonClick(() => value.acceptButtonClick);
    });
  });

  const alert_popup_class = `alert-popup${visible ? " visible" : ""}`;

  return (
    <div className={alert_popup_class}>
      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <div className="box">
          <div className="header">{title}</div>
          <div className="body">
            {message}
            {Children && <Children />}
          </div>
          <div className="footer">
            <Button
              type="submit"
              label={acceptButton}
              onClick={handleAcceptClick}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
