import "./style.scss";

import { useEffect, useState } from "react";

import logx from "../../helpers/logx";
import { CardItem } from "../../models/card-item";
import PlayingCard from "../playing-card";

interface IProps {
  playerName?: string;
  items: CardItem[];
  average?: CardItem;
  waiting?: boolean;
  started?: boolean;
  stopped?: boolean;
  onSelect: (card: CardItem) => void;
}

export default function PlayerPopup({
  playerName,
  items,
  average,
  waiting,
  started,
  stopped,
  onSelect,
}: IProps) {
  const [selected, setSelected] = useState<CardItem>({ value: "", weight: -1 });
  const cardsBoxClassList = `cards-box${started && stopped ? " result" : ""}`;
  const popupClassList = `popup${started && !stopped ? " active" : ""}`;

  useEffect(() => {
    if (started && stopped) setSelected({ value: "", weight: -1 });
  }, [started, stopped]);

  const handleSelect = (card: CardItem) => {
    if (waiting || stopped) return;
    setSelected(card);
    onSelect && onSelect(card);
  };

  return (
    <div className={popupClassList}>
      <div className="text">
        <div className="player">{playerName}</div>
        {waiting || stopped
          ? ", aguarde o início da sessão"
          : started
          ? ", escolha sua pontuação"
          : ""}
      </div>
      <div className={cardsBoxClassList}>
        <div className="cards">
          {average ? (
            <>
              <PlayingCard active small card={average} />
              &nbsp;&nbsp;
            </>
          ) : undefined}
          {items.map((card, idx) =>
            card.value === selected.value ? (
              <PlayingCard key={idx} small place />
            ) : (
              <PlayingCard
                active={started && !stopped}
                key={idx}
                small
                card={card}
                onClick={handleSelect}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
