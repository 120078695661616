import {
  addDoc,
  collection,
  doc,
  getDoc,
  runTransaction,
} from "firebase/firestore";

import logx from "../helpers/logx";
import { CardItem } from "../models/card-item";
import { Player } from "../models/player";
import { Session } from "../models/session";
import { db } from "../services/firebase-service";

export default class SessionRepository {
  sessionsCollection = "sessions";

  getDocRef(sessionId: string) {
    return doc(db, this.sessionsCollection, sessionId);
  }

  private async _updateAdapter(
    sessionId: string,
    callback: (data: any) => any
  ) {
    const docRef = this.getDocRef(sessionId);
    return await runTransaction(db, async (transaction) => {
      const docSnap = await transaction.get(docRef);
      if (!docSnap.exists()) return;
      const data = callback(docSnap.data());
      transaction.update(docRef, data);
      return data;
    });
  }

  async loadSession(sessionId: string): Promise<Session | null> {
    const docRef = this.getDocRef(sessionId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      logx.error("[service] [load] session not found", sessionId);
      return null;
    }
    return docSnap.data() as Session;
  }

  async joinSession(sessionId: string, player: Player) {
    return this._updateAdapter(sessionId, (data: any) => {
      data.players = data.players
        ? [...data.players.filter((x: any) => x.uid !== player.uid), player]
        : [player];
      return data;
    });
  }

  async startSession(sessionId: string, player: Player) {
    return this._updateAdapter(sessionId, (data: any) => {
      data.players = data.players.map((x: Player) => ({ ...x, card: null }));
      data.startedAt = Date.now();
      data.startedBy = { ...player };
      data.stoppedAt = null;
      data.stoppedBy = null;
      return data;
    });
  }

  async stopSession(sessionId: string, player: Player) {
    return this._updateAdapter(sessionId, (data: any) => {
      if (!data.startedAt) return;
      data.stoppedAt = Date.now();
      data.stoppedBy = player;
      return data;
    });
  }

  async cardSelected(sessionId: string, player: Player, card: CardItem) {
    try {
      return this._cardSelectedUpdate(sessionId, player, card);
    } catch (e) {
      logx.service("cardSelected.retry");
      setTimeout(() => {
        return this._cardSelectedUpdate(sessionId, player, card);
      }, 200);
    }
  }

  private async _cardSelectedUpdate(
    sessionId: string,
    player: Player,
    card: CardItem
  ) {
    return this._updateAdapter(sessionId, (data: any) => {
      data.players = data.players?.map((x: any) =>
        x.uid === player.uid ? { ...x, card: card } : x
      );
      return data;
    });
  }

  async createSession(session: Session): Promise<string> {
    const docRef = await addDoc(collection(db, this.sessionsCollection), {
      ...session,
    });
    return docRef.id;
  }
}
