import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AlertPopup from "../../components/alert-popup";

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") navigate("/enter-session");
  }, [location, navigate]);

  return (
    <div className="container">
      <AlertPopup />
      <Outlet />
    </div>
  );
};

export default Main;
