import { logEvent } from "firebase/analytics";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EnterSessionForm from "../../../components/enter-session-form";
import logx from "../../../helpers/logx";
import { Player } from "../../../models/player";
import AlertService from "../../../services/alert-service";
import FirebaseService, { analytics } from "../../../services/firebase-service";
import { useService } from "../../../services/service-locator/DecouplerContext";
import SessionService from "../../../services/session-service";

export default function EnterSessionPage() {
  const navigate = useNavigate();
  const { sessionId } = useParams<any>();
  const alertService = useService<AlertService>("AlertService");
  const firebaseService = useService<FirebaseService>("FirebaseService");
  const service = useService<SessionService>("SessionService");
  const newSession = !sessionId;
  const [playerName, setPlayerName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [buttonLabel, setButtonLabel] = useState(
    newSession ? "nova sessão" : "entrar na sessão"
  );

  let screen_view = useRef(false);

  useEffect(() => {
    if (screen_view.current) return;
    screen_view.current = true;

    logx.page("[register] screen view to analytics");

    logEvent(analytics, "screen_view", {
      firebase_screen: "enter-session",
      firebase_screen_class: "EnterSessionPage",
    });
  }, []);

  useEffect(() => {
    if (!sessionId) return;
    logx.page("[owner] subscribe", `${sessionId}`);

    async function authAndLoad() {
      if (!sessionId) return;
      if (!firebaseService.userUid) await firebaseService.signInAnonymouysly();
      await service.loadSession(sessionId, firebaseService.userUid);
    }
    authAndLoad();
    const ownSub = service.ownerPlayer.subscribe((ownerPlayer) => {
      setOwnerName(ownerPlayer.name);
    });

    return () => {
      logx.page("[owner] unsubscribe", `${sessionId}`);
      ownSub.unsubscribe();
    };
  }, [firebaseService, service, sessionId]);

  async function handleNewSession(player: Player) {
    if (!newSession) return;
    const id = await service.createSession(player);
    if (id) navigate(`/session/${id}`);
  }

  async function handleJoinSession(player: Player) {
    if (newSession || !sessionId) return;
    const id = await service.joinSession(sessionId, player);
    if (id) navigate(`/session/${id}`);
  }

  async function getPlayer(): Promise<Player | null> {
    if (!playerName || playerName.length < 3) {
      alertService.alert("Erro", "Nome de usuário inválido.", "ok");
      return null;
    }
    setButtonLabel("entrando...");
    await firebaseService.signInAnonymouysly();
    return { uid: firebaseService.userUid, name: playerName };
  }

  async function handleClick() {
    const player = await getPlayer();
    if (!player) return;

    handleNewSession(player);
    handleJoinSession(player);
  }

  function handleChange(value: any) {
    value && setPlayerName(value);
  }

  return (
    <div className="screen-center">
      <EnterSessionForm
        buttonLabel={buttonLabel}
        ownerName={ownerName}
        newSession={newSession}
        onClick={handleClick}
        onChange={handleChange}
      />
    </div>
  );
}
