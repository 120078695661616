import { collection, getDocs, query, where } from "firebase/firestore";

import { Session } from "../models/session";
import { db } from "./firebase-service";

export default class AdminService {
  sessionsCollection = "sessions";

  async cleanupSessions() {
    const docsRef = collection(db, this.sessionsCollection);
    const q = query(docsRef, where("owner.name", "==", "Angelito"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data() as Session;
      console.log(
        doc.id,
        new Date(data.createdAt ?? 0).toISOString(),
        data.owner?.name
      );
      //deleteDoc(doc.ref);
    });
  }
}
