import "./style.scss";

import PlayerPopup from "../../../components/player-popup";
import PollHeader from "../../../components/poll-header";
import TableCards from "../../../components/table-cards";
import useSession from "./useSession";

export default function SessionPage() {
  const {
    average,
    players,
    playerName,
    started,
    stopped,
    waiting,
    tableCards,
    handleAction,
    handleShare,
    handleExit,
    handleCardSelect,
  } = useSession();

  return (
    <div className="session-box">
      <PollHeader
        started={started}
        stopped={stopped}
        onClick={handleAction}
        onShare={handleShare}
        onExit={handleExit}
      />
      <TableCards players={players} started={started} stopped={stopped} />
      <PlayerPopup
        items={tableCards}
        average={average}
        playerName={playerName}
        waiting={waiting}
        started={started}
        stopped={stopped}
        onSelect={handleCardSelect}
      />
    </div>
  );
}
