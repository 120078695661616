import { logEvent } from "firebase/analytics";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InputText from "../../../components/input-text";
import logx from "../../../helpers/logx";
import { fibonacci } from "../../../helpers/points";
import { CardItem } from "../../../models/card-item";
import { Player } from "../../../models/player";
import AlertService from "../../../services/alert-service";
import FirebaseService, { analytics } from "../../../services/firebase-service";
import { useService } from "../../../services/service-locator/DecouplerContext";
import SessionHelper from "../../../services/session-helper";
import SessionService from "../../../services/session-service";

export default function useSession() {
  const { sessionId } = useParams<any>();
  const navigate = useNavigate();
  const alertService = useService<AlertService>("AlertService");
  const firebaseService = useService<FirebaseService>("FirebaseService");
  const sessionService = useService<SessionService>("SessionService");
  const [playerName, setPlayerName] = useState("");
  const [playerUid, setPlayerUid] = useState("");
  const [players, setPlayers] = useState<Player[]>([]);
  const [waiting, setWaiting] = useState(true); // true
  const [started, setStarted] = useState(false); // false
  const [stopped, setStopped] = useState(false); // false
  const [average, setAverage] = useState<CardItem>();
  const [tableCards, setTableCards] = useState<CardItem[]>(fibonacci);

  let screen_view = useRef(false);

  // ANALYTICS
  useEffect(() => {
    if (screen_view.current) return;
    screen_view.current = true;

    logx.page("[register] screen view to analytics");
    logEvent(analytics, "screen_view", {
      firebase_screen: "session",
      firebase_screen_class: "SessionPage",
    });
  }, []);

  // USER SIGN IN
  useLayoutEffect(() => {
    logx.warn(">> [start]", sessionId);
    sessionService.validateUser(firebaseService, sessionId);

    return () => {
      logx.pageReturn("<< [start]");
    };
  }, [firebaseService, sessionService, sessionId]);

  // SESSION STATUS (USERUID, SESSIONID)
  useEffect(() => {
    logx.page(">> [sessionStatus] [subscribe] to session status");

    if (!firebaseService.userUid) return;

    const serviceSub = sessionService.sessionStatus.subscribe((value) => {
      if (sessionService.userInValidation) return;

      if (value.userInSession) {
        logx.page("[sessionStatus] set user uid and name to state");
        setPlayerUid(firebaseService.userUid);
        setPlayerName(sessionService.playerName);
        return;
      }

      if (value.sessionIsValid) {
        logx.error("[page] [sessionStatus] user not found in current session");
        logx.warn(`[page] [sessionStatus] [redirect] ${sessionId}`);
        navigate(`/enter-session/${sessionId}`);
        return;
      }

      logx.error(
        "[page] [sessionStatus] [redirect] invalid session",
        sessionId
      );
      navigate(`/enter-session`);
    });

    return () => {
      logx.pageReturn("<< [sessionStatus] [unsubscribe] to session status");
      serviceSub.unsubscribe();
    };
  }, [
    firebaseService.userUid,
    navigate,
    sessionService.playerName,
    sessionService.sessionStatus,
    sessionService.userInValidation,
    sessionId,
  ]);

  // SESSION LISTENING
  useEffect(() => {
    logx.page(">> [listener] [register] session listener");

    if (sessionId && playerUid && !sessionService.listener) {
      sessionService.listenSession(sessionId);
    }

    return () => {
      logx.pageReturn("<< [listener] [unregister] session listener");
      sessionService.listener && sessionService.listener();
      sessionService.listener = undefined;
    };
  }, [playerUid, sessionId, sessionService]);

  // SESSION UPDATES
  useEffect(() => {
    logx.page(">> [session] [subscribe]");

    const serviceSub = sessionService.session.subscribe((value) => {
      logx.warn("[page] [session] update session data");
      if (value?.players)
        setPlayers(
          value.players.map((player) => {
            const name = player.name.split(" ");
            const parts = {
              ...player,
              name: `${name[0]} ${
                name.length > 1
                  ? `${name[1][0]}${name[1].length > 1 ? "." : ""}`
                  : ""
              }`,
            };
            return parts;
          })
        );
      setWaiting(sessionService.waiting);
      setStarted(sessionService.started);
      setStopped(sessionService.stopped);
    });

    return () => {
      logx.pageReturn("<< [session] [unsubscribe]");
      serviceSub.unsubscribe();
    };
  }, [
    sessionService.session,
    sessionService.started,
    sessionService.stopped,
    sessionService.waiting,
  ]);

  // SESSION RESULT
  useEffect(() => {
    logx.page(">> [sessionResult] [subscribe]");

    const resultSub = SessionHelper.sessionResult.subscribe((value) => {
      logx.warn("[page] [sessionResult] update session result");
      setAverage(value.average);
      setTableCards(value.tableCards);
    });

    return () => {
      logx.pageReturn("<< [sessionResult] [unsubscribe]");
      resultSub.unsubscribe();
    };
  }, []);

  const handleAction = () => {
    if (!sessionId) return;

    if (sessionService.running) {
      sessionService.stopSession(sessionId);
      return;
    }
    sessionService.startSession(sessionId);
  };

  const getLocation = () => window.location.toString();

  const handleShare = () => {
    const shareInput = () =>
      InputText({
        name: "share",
        defaultValue: getLocation(),
      });

    alertService.prompt(
      "Compartilhar sessão",
      shareInput,
      "Copiar",
      handleCopyLink
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(getLocation());
    alertService.alert(
      "Compartilhar",
      "Link copiado para área de transferência!",
      "ok"
    );
  };

  const handleExit = () => {
    logx.page("SessionPage.handleExit");
    navigate("/exit-session");
  };

  const handleCardSelect = (card: CardItem) => {
    if (!sessionService.running) return;
    if (sessionId) sessionService.cardSelected(sessionId, card);
  };

  return {
    average,
    players,
    playerName,
    waiting,
    started,
    stopped,
    tableCards,
    handleAction,
    handleShare,
    handleExit,
    handleCardSelect,
  };
}
