import Button from "../../components/button";
import AdminService from "../../services/admin-service";
import { useService } from "../../services/service-locator/DecouplerContext";

export default function IndexPage() {
  const service = useService<AdminService>("AdminService");
  const handleCleanup = () => {
    service.cleanupSessions();
  };

  return (
    <div>
      <h1>Home</h1>
      {process.env.NODE_ENV === "development" ? (
        <div>
          <Button label="limpar" onClick={handleCleanup} />
        </div>
      ) : undefined}
    </div>
  );
}
