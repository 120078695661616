import { createBrowserRouter } from "react-router-dom";

import EnterSessionPage from "./pages/game/enter-session";
import ExitSessionPage from "./pages/game/exit-session";
import SessionPage from "./pages/game/session";
import HomePage from "./pages/home";
import LibraryPage from "./pages/library";
import ErrorPage from "./shared/error";
import Main from "./shared/main";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Main />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/enter-session",
          element: <EnterSessionPage />,
        },
        {
          path: "/exit-session",
          element: <ExitSessionPage />,
        },
        {
          path: "/enter-session/:sessionId",
          element: <EnterSessionPage />,
        },
        {
          path: "/session/:sessionId",
          element: <SessionPage />,
        },
        {
          path: "/library",
          element: <LibraryPage />,
        },
      ],
    },
  ]
  // {
  //   basename: process.env.NODE_ENV === "development" ? "" : "",
  // }
);

export default router;
