import { logEvent } from "firebase/analytics";
import { onSnapshot, Unsubscribe } from "firebase/firestore";
import { BehaviorSubject } from "rxjs";

import logx from "../helpers/logx";
import { CardItem } from "../models/card-item";
import { Player } from "../models/player";
import { Session } from "../models/session";
import { SessionStatus } from "../models/session-result";
import FirebaseService, { analytics } from "../services/firebase-service";
import SessionHelper from "./session-helper";
import SessionRepository from "./session-repository";

const emptyPlayer: Player = { uid: "", name: "" };

export default class SessionService {
  session: BehaviorSubject<Session> = new BehaviorSubject({});
  ownerPlayer: BehaviorSubject<Player> = new BehaviorSubject(emptyPlayer);
  currentPlayer: BehaviorSubject<Player> = new BehaviorSubject(emptyPlayer);
  sessionStatus: BehaviorSubject<SessionStatus> =
    new BehaviorSubject<SessionStatus>({
      userInSession: false,
      sessionIsValid: false,
    });

  userIsLogged = false;
  userInValidation = false;

  listener?: Unsubscribe;

  get playerName() {
    return this.currentPlayer.value.name;
  }

  get waiting(): boolean {
    return !this.session.value.startedAt && !this.session.value.stoppedAt;
  }

  get started(): boolean {
    return !!this.session.value.startedAt;
  }

  get stopped(): boolean {
    return !!this.session.value.stoppedAt;
  }

  get running(): boolean {
    return this.started && !this.stopped;
  }

  get players(): Player[] {
    return this.session.value.players ?? [];
  }

  private sessionRepository: SessionRepository;
  private sessionHelper: SessionHelper;
  constructor() {
    this.sessionRepository = new SessionRepository();
    this.sessionHelper = new SessionHelper();
  }

  getPlayer(uid: string): Player | undefined {
    return this.players.find((x) => x.uid === uid);
  }

  userIsOwner(uid: string): boolean {
    return this.ownerPlayer.value.uid === uid;
  }

  userInSession(uid: string): boolean {
    return this.players.some((x) => x.uid === uid);
  }

  sessionIsValid(): boolean {
    return !!this.session.value.owner;
  }

  listenSession(sessionId: string) {
    logx.service("page trying to start session listener");
    const docRef = this.sessionRepository.getDocRef(sessionId);
    this.listener = onSnapshot(docRef, (doc) => {
      const _session = doc.data() as Session;
      this.sessionHelper.calcResultAndAverage(_session);
      this.session.next(_session);
    });
    logEvent(analytics, "pp_session_listen", { sessionId });
  }

  async loadSession(sessionId: string, userUid: string) {
    if (!this.userIsLogged) return;
    logx.service("[session] trying to load session", `${sessionId}`);
    const session = await this.sessionRepository.loadSession(sessionId);
    if (!session) return null;
    this.session.next(session);
    this.ownerPlayer.next(session.owner ?? emptyPlayer);
    this.currentPlayer.next(this.getPlayer(userUid) ?? emptyPlayer);
    logEvent(analytics, "pp_session_load", { sessionId, userUid });
  }

  async joinSession(sessionId: string, player: Player) {
    this.currentPlayer.next(player);
    logx.service(
      "[session] user trying to join session",
      `${sessionId} ${player.name}`
    );
    const sessionJoined = await this.sessionRepository.joinSession(
      sessionId,
      player
    );
    const session = await this.sessionRepository.loadSession(sessionId);
    logx.service(JSON.stringify(sessionJoined, null, 2));
    logx.service(JSON.stringify(session, null, 2));
    if (session) this.session.next(session);
    logEvent(analytics, "pp_session_join", {
      sessionId,
      playerName: player.name,
    });
    this.updateSessionStatus(player.uid);
    return sessionId;
  }

  async startSession(sessionId: string) {
    const player = this.currentPlayer.value;
    logx.service("[session] user trying to start session");
    await this.sessionRepository.startSession(sessionId, player);
    logEvent(analytics, "pp_session_start", { sessionId });
  }

  async stopSession(sessionId: string) {
    const player = this.currentPlayer.value;
    logx.service("[session] user trying to stop session");
    await this.sessionRepository.stopSession(sessionId, player);
    logEvent(analytics, "pp_session_stop", { sessionId });
  }

  async cardSelected(sessionId: string, card: CardItem) {
    const player = this.currentPlayer.value;
    logx.service(
      "[session] user trying select the card",
      `${sessionId} ${player.name} ${card.value} ${card.weight}`
    );
    await this.sessionRepository.cardSelected(sessionId, player, card);
    logEvent(analytics, "pp_card_selected", {
      sessionId,
      playerName: player.name,
      card: card.value,
    });
  }

  async createSession(ownerPlayer: Player): Promise<string> {
    this.currentPlayer.next(ownerPlayer);
    const session = {
      owner: { ...ownerPlayer },
      createdAt: Date.now(),
      players: [{ ...ownerPlayer }],
    };
    const id = await this.sessionRepository.createSession(session);
    logEvent(analytics, "pp_session_create", {
      ownerPlayerName: ownerPlayer.name,
    });
    return id;
  }

  async validateUser(firebaseService: FirebaseService, sessionId?: string) {
    if (this.userIsLogged || this.userInValidation) {
      return;
    }
    this.userInValidation = true;

    logx.service("[session] validating user auth");

    const userUid = firebaseService.userUid;

    if (userUid && sessionId) {
      this.userIsLogged = true;

      await this.loadSession(sessionId, firebaseService.userUid);
      this.userInValidation = false;
      this.updateSessionStatus(userUid);
      return;
    }

    if (!sessionId) return;

    await firebaseService.signInAnonymouysly();
    this.userIsLogged = !!firebaseService.userUid;

    await this.loadSession(sessionId, firebaseService.userUid);
    this.userInValidation = false;
    this.updateSessionStatus(firebaseService.userUid);
  }

  updateSessionStatus(userUid: string) {
    logx.service("[session] trying update session status", userUid);
    const userInSession = this.userInSession(userUid);
    const sessionIsValid = this.sessionIsValid();
    logx.jsonMsg("[session]", { userInSession, sessionIsValid });
    this.sessionStatus.next({
      userInSession,
      sessionIsValid,
    });
  }
}
