import Receipt from "../bootstrap-icons/receipt";
import "./style.scss";

interface IProps {
  type?: "button" | "submit" | "reset" ;
  label?: string;
  white?: boolean;
  secondary?: boolean;
  icon?: boolean;
  onClick?: () => void;
}

export default function Button({
  type,
  label,
  white,
  secondary,
  icon,
  onClick,
}: IProps) {
  const classList = `button${icon ? " icon" : ""}${white ? " white" : ""}${
    secondary ? " secondary" : ""
  }`;

  return (
    <button type={type ?? "button"} className={classList} onClick={onClick}>
      {icon ? <Receipt /> : <div className="label">{label}</div>}
    </button>
  );
}
