import { BehaviorSubject } from "rxjs";

import { AlertPopup } from "../models/alert-popup";

export default class AlertService {
  private _default = {
    title: "Aviso",
    message: "",
    acceptButton: "ok",
    acceptButtonClick: () => this.handleAccept(),
    cancelButton: "cancelar",
    cancelButtonClick: () => this.handleCancel(),
  };

  private _alertContent = new BehaviorSubject<AlertPopup>(this._default);

  get alertContent() {
    return this._alertContent;
  }

  handleAccept() {
    this._alertContent.next({ ...this._default, visible: false });
  }
  handleCancel() {
    this._alertContent.next({ ...this._default, visible: false });
  }

  prompt(
    title: string,
    children: any,
    acceptButton?: string,
    acceptButtonClick?: () => void
  ) {
    this._alertContent.next({
      ...this._default,
      title,
      children: () => children,
      visible: true,
      acceptButton: acceptButton ?? this._default.acceptButton,
      acceptButtonClick: acceptButtonClick ?? this._default.acceptButtonClick,
    });
  }

  alert(
    title: string,
    message: string,
    acceptButton?: string,
    acceptButtonClick?: () => void
  ) {
    this._alertContent.next({
      ...this._default,
      title,
      message,
      visible: true,
      acceptButton: acceptButton ?? this._default.acceptButton,
      acceptButtonClick: acceptButtonClick ?? this._default.acceptButtonClick,
    });
  }
}
