import { logEvent } from "firebase/analytics";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import logx from "../../../helpers/logx";
import { analytics } from "../../../services/firebase-service";

export default function ExitSessionPage() {
  const navigate = useNavigate();

  let screen_view = useRef(false);

  useEffect(() => {
    if (screen_view.current) return;
    screen_view.current = true;

    logx.page("[register] screen view to analytics");
    logEvent(analytics, "screen_view", {
      firebase_screen: "exit-session",
      firebase_screen_class: "ExitSessionPage",
    });
    navigate("/");
  }, [navigate]);

  return <div>Redirecting...</div>;
}
