import Button from "../../components/button";
import EnterSessionForm from "../../components/enter-session-form";
import InputText from "../../components/input-text";
import PlayerPopup from "../../components/player-popup";
import PlayingCard from "../../components/playing-card";
import PollHeader from "../../components/poll-header";
import TableCards from "../../components/table-cards";
import { CardItem } from "../../models/card-item";
import { Player } from "../../models/player";

export default function LibraryPage() {
  return (
    <div>
      <h1>Library</h1>
      <TableCards stopped players={players} />
      <br />
      <TableCards started players={players} />
      <br />
      <TableCards players={players} />
      <br />
      <PollHeader
        taskName="Criar nova tela de expedição"
        onClick={() => console.log("start-stop click")}
        onShare={() => console.log("share click")}
        onExit={() => console.log("exit click")}
      />
      &nbsp;&nbsp;
      <PollHeader
        started
        taskName="Criar nova tela de expedição"
        onClick={() => console.log("start-stop click")}
        onShare={() => console.log("share click")}
        onExit={() => console.log("exit click")}
      />
      &nbsp;&nbsp;
      <PollHeader
        started
        stopped
        taskName="Criar nova tela de expedição"
        onClick={() => console.log("start-stop click")}
        onShare={() => console.log("share click")}
        onExit={() => console.log("exit click")}
      />
      <br />
      <br />
      <EnterSessionForm
        buttonLabel="nova sessão"
        newSession
        onClick={() => console.log("click")}
      />
      &nbsp;&nbsp;&nbsp;
      <EnterSessionForm
        buttonLabel="nova sessão"
        ownerName="Jogador"
        onClick={() => console.log("click")}
      />
      <p>
        <Button label="entrar na sessão" onClick={() => console.log("click")} />
      </p>
      <p>
        <Button
          white
          label="entrar na sessão"
          onClick={() => console.log("click")}
        />
      </p>
      <p>
        <Button
          secondary
          label="entrar na sessão"
          onClick={() => console.log("click")}
        />
      </p>
      <p>
        <Button secondary icon onClick={() => console.log("click")} />
      </p>
      <p>
        <InputText id="name" name="name" placeholder="digite seu nome" />
        &nbsp;&nbsp;
        <InputText id="name" name="name" defaultValue="seu nome" />
      </p>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PlayingCard card={{ label: "Jogador", value: 13, weight: 13 }} />
        <PlayingCard
          card={{ label: "Jogador", value: 13, weight: 13 }}
          active
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PlayingCard card={{ label: "Jogador", value: "", weight: -1 }} back />
        <PlayingCard
          card={{ label: "Jogador", value: "", weight: -1 }}
          back
          active
        />
        <PlayingCard card={{ label: "Jogador", value: "", weight: -1 }} place />
      </div>
      <br />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PlayingCard small card={{ value: 13, weight: 13 }} />
        <PlayingCard small card={{ value: 13, weight: 13 }} active />
        <PlayingCard small back />
        <PlayingCard small back active />
        <PlayingCard small place />
        <PlayingCard
          card={{ label: "Média", value: "13", weight: 13 }}
          small
          active
        />
        <PlayingCard card={{ label: "3", value: "8", weight: 8 }} small />
      </div>
      <div>
        <div>
          <PlayerPopup
            waiting
            playerName="Jogador"
            items={items}
            onSelect={() => {}}
          />
        </div>
        <div>
          <PlayerPopup
            started
            playerName="Jogador"
            items={items}
            onSelect={() => {}}
          />
        </div>
        <div>
          <PlayerPopup items={summary} average={average} onSelect={() => {}} />
        </div>
      </div>
    </div>
  );
}

const items: CardItem[] = [
  { value: "0", weight: 0 },
  { value: "1", weight: 1 },
  { value: "2", weight: 2 },
  { value: "3", weight: 3 },
  { value: "5", weight: 5 },
  { value: "8", weight: 8 },
  { value: "13", weight: 13 },
  { value: "21", weight: 21 },
  { value: "34", weight: 34 },
];

const summary: CardItem[] = [
  { label: "2", value: "5", weight: 5 },
  { label: "1", value: "8", weight: 8 },
  { label: "6", value: "13", weight: 13 },
  { label: "1", value: "21", weight: 21 },
];

const average: CardItem = {
  label: "Média",
  value: 13,
  weight: 13,
};

const players: Player[] = [
  { uid: "1001", name: "Angelito" },
  { uid: "1001", name: "Marjorie", card: { value: "13", weight: 0 } },
  { uid: "1001", name: "Giovanni" },
  { uid: "1001", name: "Lucas" },
  { uid: "1001", name: "Gabriel" },
  { uid: "1001", name: "Giovanna" },
  { uid: "1001", name: "Isabela" },
  { uid: "1001", name: "Eduardo" },
  { uid: "1001", name: "Rafael" },
  { uid: "1001", name: "Leonardo" },
  { uid: "1001", name: "Debora" },
];
