import Button from "../button";
import InputText from "../input-text";
import logotipo from "../../assets/logo-cards.png";
import "./style.scss";

interface IProps {
  buttonLabel: string;
  newSession?: boolean;
  ownerName?: string;
  onClick: () => void;
  onChange?: (e: any) => void;
}

export default function EnterSessionForm({
  buttonLabel,
  newSession,
  ownerName,
  onClick,
  onChange,
}: IProps) {
  return (
    <div className="form-box">
      <div className="logo">
        <img src={logotipo} alt="logotipo" width={165} height={165} />
        <div className="title">Planning Poker</div>
      </div>
      {!newSession && ownerName ? (
        <div className="message">
          Sessão criada por <span className="player">{ownerName}</span>
        </div>
      ) : undefined}
      <form className="form" onSubmit={(e) => e.preventDefault()}>
        <InputText
          id="name"
          autoFocus
          name="name"
          defaultValue=""
          onChange={onChange}
          placeholder="digite seu nome"
        />
        <Button type="submit" label={buttonLabel} onClick={onClick} />
      </form>
    </div>
  );
}
