import "./style.scss";

import { CardItem } from "../../models/card-item";

interface IProps {
  card?: CardItem;
  active?: boolean;
  back?: boolean;
  place?: boolean;
  small?: boolean;
  onClick?: (card: CardItem) => void;
}

export default function PlayingCard({
  card,
  active,
  back,
  place,
  small,
  onClick,
}: IProps) {
  const classList = `p-card${active ? " active" : ""}${back ? " back" : ""}${
    place ? " place" : ""
  }${small ? " small" : ""}`;

  const numberClasslist = `number${small ? " small" : ""}`;
  const backClasslist = `back${small ? " small" : ""}`;
  const labelClassList = `label${small ? " small" : ""}`;

  const handleClick = () => {
    onClick && card && onClick(card);
  };

  return (
    <div className="p-card-box" onClick={handleClick}>
      <div className={classList}>
        {!back && !place ? (
          <div className={numberClasslist}>{card?.value}</div>
        ) : back ? (
          <div className={backClasslist}></div>
        ) : undefined}
      </div>
      <div className={labelClassList}>{card?.label}</div>
    </div>
  );
}
