import "./style.scss";
import Exit from "../bootstrap-icons/exit";
import Play from "../bootstrap-icons/play";
import Share from "../bootstrap-icons/share";

interface IProps {
  taskName?: string;
  started?: boolean;
  stopped?: boolean;
  onClick: () => void;
  onShare?: () => void;
  onExit: () => void;
}

export default function PollHeader({
  taskName,
  started,
  stopped,
  onClick,
  onShare,
  onExit,
}: IProps) {
  const buttonLabel =
    !started && !stopped
      ? "iniciar votação"
      : started && !stopped
      ? "revelar votos"
      : "votar próxima tarefa";
  taskName = "Planning Poker";

  return (
    <div className="poll-header">
      {/* <div className="timer">
        <div className="text">Tarefa em votação</div>
        <div>00:00</div>
      </div> */}
      <div className="task-name">{taskName}</div>
      <div className="actions">
        <button className="start-stop" type="button" onClick={onClick}>
          <Play color="#fff" />
          <div className="label">{buttonLabel}</div>
        </button>
        {onShare && (
          <button className="share" type="button" onClick={onShare}>
            <Share />
          </button>
        )}
        <button className="exit" type="button" onClick={onExit}>
          <Exit />
        </button>
      </div>
    </div>
  );
}
